<template>
  <b-row>
    <b-col
      cols="6"
      class="mx-auto"
    >
      <b-card
        title="Add New Department"
        no-body
      >
        <b-card-header class="d-flex align-items-right">
          <h5 class="mb-0">
            Add New Support Department
          </h5>
          <b-link
            to="/tickets/departments"
            class="btn btn-sm btn-secondary"
          ><feather-icon icon="ArrowLeftIcon" /> View All Departments</b-link>
        </b-card-header>
        <b-card-body>
          <div
            v-if="message"
            id="message"
            class="alert alert-danger p-1"
          >
            {{ message }}
          </div>
          <div
            v-if="success"
            id="message"
            class="alert alert-success p-1"
          >
            {{ success }}
          </div>
          <b-form-group label="Department Name">
            <b-form-input v-model="name" />
          </b-form-group>
          <b-form-group label="Department Email Address">
            <b-form-input v-model="email" />
          </b-form-group>
          <b-form-group label="Status">
            <b-form-select
              v-model="status"
              :options="statusOptions"
            />
          </b-form-group>
          <b-button
            variant="primary"
            :disabled="!name || !email || isSaving"
            @click="saveDepartment"
          >Submit</b-button>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BLink,
} from 'bootstrap-vue'
import axios from 'axios'

export default {
  name: 'AddNewDepartment',

  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCardHeader,
    BCardBody,
    BLink,
  },
  data() {
    return {
      name: '',
      email: '',
      status: true,
      statusOptions: [
        { value: true, text: 'Active' },
        { value: false, text: 'Disabled' },
      ],
      isSaving: false,
      message: '',
      success: '',
    }
  },
  methods: {
    async saveDepartment() {
      try {
        this.isSaving = true
        const formData = new FormData()
        formData.append('name', this.name)
        formData.append('email', this.email)
        formData.append('status', this.status)
        const { data } = await axios.post('/support/departments', formData)
        this.success = data.message
        this.message = ''
        this.name = ''
        this.email = ''
        this.status = true
      } catch (e) {
        this.message = e.response.data.error.message
      } finally {
        this.isSaving = false
      }
    },
  },
}
</script>
